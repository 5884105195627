/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "VisaDialect-light";
  src: url("./assets/fonts/VisaDialect-Light.ttf") format("truetype");
  font-weight: lighter;
}
@font-face {
  font-family: "VisaDialect";
  src: url("./assets/fonts/VisaDialect-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "VisaDialect-medium";
  src: url("./assets/fonts/VisaDialect-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "VisaDialect-semibold";
  src: url("./assets/fonts/VisaDialect-Semibold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "VisaDialect-bold";
  src: url("./assets/fonts/VisaDialect-Bold.ttf") format("truetype");
  font-weight: bolder;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "VisaDialect", "Helvetica Neue", sans-serif;
}
